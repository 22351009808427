import { apolloClient } from '../graphql';
import checkUserRegistered from '../graphql/checkUserRegistered.gql';

export default async function profilCompleted({ next, router }) {
  const { data } = await apolloClient.query({
    query: checkUserRegistered,
    fetchPolicy: 'network-only',
  });

  if (!data.checkUserRegistered) {
    const prevPath = router.currentRoute.value.path;
    const routeParams = { name: 'chatbotByMachineName', params: { machineName: 'profil' } };
    const routeQuery = (prevPath === '/mes-etapes/me-preparer') ? {} : { redirect: prevPath };

    return router.push({ ...routeParams, query: routeQuery });
  }

  return next();
}
