import { createRouter, createWebHistory } from 'vue-router';

// Middleware
import auth from '../middleware/auth';
import guest from '../middleware/guest';
import guestChatbot from '../middleware/guestChatbot';
import profilCompleted from '../middleware/profilCompleted';
import diagnosticCompleted from '../middleware/diagnosticCompleted';
import a1Connect from '../middleware/a1Connect';

// MySteps components
const MySteps = () => import(/* webpackChunkName: "MySteps" */ '_pages/mySteps.vue');
const Resources = () => import(/* webpackChunkName: "Resources" */ '_pages/resources.vue');
const ObjectivePro = () => import(/* webpackChunkName: "Resources" */ '_pages/objectivePro.vue');
const ResourcesSingle = () => import(/* webpackChunkName: "Resources" */ '_pages/resourcesSingle.vue');
const MySoftSkills = () => import(/* webpackChunkName: "MySoftSkills" */ '_pages/mySoftSkills.vue');
const mySoftSkillsAndMyJourney = () => import(/* webpackChunkName: "MySoftSkillsAndMyJourney" */ '_pages/mySoftSkillsAndMyJourney.vue');
const MyJourney = () => import(/* webpackChunkName: "MyJourney" */ '_pages/myJourney.vue');
const MySettings = () => import(/* webpackChunkName: "MyJourney" */ '_pages/mySettings.vue');
const BadgeDisplayer = () => import(/* webpackChunkName: "BadgeDisplayer" */ '_pages/badgeDisplayer.vue');
const RegisterWithPassportEngagementCode = () => import(/* webpackChunkName: "RegisterWithPassportEngagementCode" */ '_pages/registerWithPassportEngagementCode.vue');
const LandingPage = () => import(/* webpackChunkName: "LandingPage" */ '_pages/landingPages.vue');

const ExperiencesSelectModal = () => import(/* webpackChunkName: "ExperiencesSelectModal" */ '_pages/SelectModal.vue');

// DiscoverSoftSkills components
const DiscoverSoftSkills = () => import(/* webpackChunkName: "DiscoverSoftSkills" */ '_pages/discoverSoftSkills.vue');

// Experiences components
const IframeExperiences = () => import(/* webpackChunkName: "Experiences" */ '_components/iframe/experiences.vue');
const ExternalEvaluationWithUrl = () => import(/* webpackChunkName: "ExternalEvaluationWithUrl" */ '_pages/externalEvaluationWithUrl.vue');
const ExternalEvaluationStart = () => import(/* webpackChunkName: "ExternalEvaluationStart" */ '_pages/externalEvaluationStart.vue');
const ExperienceAutoEvaluation = () => import(/* webpackChunkName: "ExperienceAutoEvaluation" */ '_pages/evaluation.vue');
const StartAutoEvaluation = () => import(/* webpackChunkName: "StartAutoEvaluation" */ '_pages/startAutoEvaluation.vue');
const ExperienceContact = () => import(/* webpackChunkName: "ExperienceContact" */ '_pages/experienceContact.vue');
const ValueByMail = () => import(/* webpackChunkName: "ValueByMail" */ '_pages/valueByMail.vue');

// ProgressResources components
const ProgressResources = () => import(/* webpackChunkName: "ProgressResources" */ '_pages/progressResources.vue');
const SingleProgressResources = () => import(/* webpackChunkName: "ProgressResources" */ '_pages/singleProgressResource.vue');
const ProResourceSingle = () => import(/* webpackChunkName: "ProgressResources" */ '_pages/proResourceSingle.vue');

// Missions components
const Missions = () => import(/* webpackChunkName: "Missions" */ '_pages/missions.vue');
const SingleMission = () => import(/* webpackChunkName: "SingleMission" */ '_pages/singleMission.vue');

// Jobs components
const Jobs = () => import(/* webpackChunkName: "Progress" */ '_pages/jobs.vue');
const JobsSingle = () => import(/* webpackChunkName: "Progress" */ '_pages/jobsSingle.vue');

// Mails components
const Mails = () => import(/* webpackChunkName: "Mails" */ '_pages/mails.vue');

// Logout components
const Accueil = () => import(/* webpackChunkName: "homepage" */ '_pages/homepage.vue');
const CommentCaMarche = () => import(/* webpackChunkName: "commentCaMarche" */ '_pages/commentCaMarche.vue');
const SoftSkills = () => import(/* webpackChunkName: "softSkills" */ '_pages/softSkills.vue');
const SoftSkillsFamily = () => import(/* webpackChunkName: "softSkillsFamily" */ '_pages/softSkillsFamily.vue');
const DevenirPartenaire = () => import(/* webpackChunkName: "devenirPartenaire" */ '_pages/devenirPartenaire.vue');
const NotreOrganisation = () => import(/* webpackChunkName: "notreOrganisation" */ '_pages/notreOrganisation.vue');
const ReveleLaForceQuiEstEnToi = () => import(/* webpackChunkName: "reveleLaForceQuiEstEnToi" */ '_pages/reveleLaForceQuiEstEnToi.vue');
const Macif = () => import(/* webpackChunkName: "landingMacif" */ '_pages/landingMacif.vue');

// Chatbot components
const Chatbot = () => import(/* webpackChunkName: "Chatbot" */ '_pages/chatbot.vue');
const iframeChatbot = () => import(/* webpackChunkName: "Chatbot" */ '_components/iframe/chatbot.vue');

// ExperienceEaluationReferent components
const IframeExperienceEvaluationReferent = () => import(/* webpackChunkName: "ExperienceEaluationReferent" */ '_components/iframe/evaluationReferent.vue');

// Menu components
const Contact = () => import(/* webpackChunkName: "Menu" */ '_pages/contact.vue');
const APropos = () => import(/* webpackChunkName: "Menu" */ '_pages/apropos.vue');
const MentionsLegales = () => import(/* webpackChunkName: "Menu" */ '_pages/mentionsLegales.vue');
const Manifeste = () => import(/* webpackChunkName: "Menu" */ '_pages/manifeste.vue');

// Others
const MissionToSkills = () => import(/* webpackChunkName: "TranslateJobSoftskills" */ '_pages/missionToSkills.vue');
const PhyGital = () => import(/* webpackChunkName: "PhyGital" */ '_pages/phygital.vue');
const TranslateJobSoftskills = () => import(/* webpackChunkName: "TranslateJobSoftskills" */ '_pages/translateJobSoftskills.vue');

// Error components
const PageNotFound = () => import(/* webpackChunkName: "Error" */'_components/errors/404.vue');
const NotAuthorized = () => import(/* webpackChunkName: "Error" */'_components/errors/401.vue');
const ServerInternalError = () => import(/* webpackChunkName: "Error" */ '_components/errors/500.vue');
const ServerError = () => import(/* webpackChunkName: "Error" */ '_components/errors/503.vue');

// Resume components
const ResumeHelps = () => import(/* webpackChunkName: "ResumeHelps" */ '_pages/resumeHelps.vue');
const ResumeHelpsStart = () => import(/* webpackChunkName: "resumeHelpsStart" */ '_pages/resumeHelpsStart.vue');
const ResumeEvaluation = () => import(/* webpackChunkName: "ResumeEvalution" */ '_pages/resumeEvaluation.vue');
const ResumeEvaluated = () => import(/* webpackChunkName: "resumeEvaluated" */ '_pages/resumeEvaluated.vue');

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'index',
      component: Accueil,
      meta: {
        middleware: [guest],
      },
    },
    {
      path: '/inscription',
      name: 'register',
      component: Accueil,
      meta: {
        middleware: [guest, a1Connect],
      },
    },
    {
      path: '/passeport-engagement',
      name: 'passeportEngagement',
      component: RegisterWithPassportEngagementCode,
    },
    {
      path: '/decouvre-tes-softskills/:slug',
      name: 'landingPage',
      component: LandingPage,
    },
    {
      path: '/connexion',
      name: 'login',
      component: Accueil,
      meta: {
        middleware: [guest, a1Connect],
      },
    },
    {
      path: '/chatbot',
      name: 'chatbotLogout',
      component: Chatbot,
      meta: {
        middleware: [guestChatbot],
      },
    },
    {
      path: '/chatbot/:machineName',
      name: 'chatbotByMachineName',
      component: Chatbot,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: '/newpwd/:token?',
      name: 'accueilNewpwd',
      component: Accueil,
    },
    {
      path: '/reset-password',
      name: 'resetPwd',
      component: Accueil,
    },
    {
      path: '/mes-etapes/:activeTab',
      name: 'mySteps',
      component: MySteps,
      meta: {
        middleware: [auth, profilCompleted, diagnosticCompleted],
      },
    },
    {
      path: '/ressources',
      name: 'resources',
      component: Resources,
      meta: {
        middleware: [auth, profilCompleted],
      },
    },
    {
      path: '/ressources/mail-assistant/:mailAssistantId/creer',
      name: 'mails',
      component: Mails,
      meta: {
        middleware: [auth, profilCompleted],
      },
    },
    {
      path: '/ressources/mon-projet-professionnel',
      name: 'objectivePro',
      component: ObjectivePro,
      meta: {
        middleware: [auth, profilCompleted],
      },
    },
    {
      path: '/ressources/traduction-job-softskills',
      name: 'translate_job_softskills',
      component: TranslateJobSoftskills,
      meta: {
        middleware: [auth, profilCompleted],
      },
    },
    {
      path: '/ressources/decouvrir-les-soft-skills',
      name: 'competences',
      component: DiscoverSoftSkills,
      meta: {
        middleware: [auth, profilCompleted],
      },
      children: [
        {
          path: ':id',
          name: 'uniqueCompetences',
          component: DiscoverSoftSkills,
          meta: {
            middleware: [auth, profilCompleted],
          },
        },
      ],
    },
    {
      path: '/ressources/resume-evaluation/:token',
      name: 'resumeEvaluation',
      component: ResumeEvaluation,
    },
    {
      path: '/ressources/resume-confirmation/:uri',
      name: 'resumeEvaluated',
      component: ResumeEvaluated,
    },
    {
      path: '/ressources/resume-helps',
      name: 'resumeHelps',
      component: ResumeHelps,
      meta: {
        middleware: [auth, profilCompleted],
      },
    },
    {
      path: '/ressources/resume-helps/start',
      name: 'resumeHelpsStart',
      component: ResumeHelpsStart,
      meta: {
        middleware: [auth, profilCompleted],
      },
    },
    {
      path: '/ressources/resume-evaluation/:token',
      name: 'resumeEvaluation',
      component: ResumeEvaluation,
    },
    {
      path: '/ressources/:resourceId',
      name: 'resourcesSingle',
      component: ResourcesSingle,
      meta: {
        middleware: [auth, profilCompleted],
      },
    },
    {
      path: '/mes-parametres',
      name: 'mySettings',
      component: MySettings,
      meta: {
        middleware: [auth, profilCompleted],
      },
    },
    {
      path: '/progresser',
      name: 'progressResources',
      component: ProgressResources,
      meta: {
        middleware: [auth, profilCompleted],
      },
    },
    {
      path: '/progresser/:resourceId',
      name: 'singleProgressResources',
      component: SingleProgressResources,
      meta: {
        middleware: [auth, profilCompleted],
      },
    },
    {
      path: '/m-engager',
      name: 'missions',
      component: Missions,
      meta: {
        middleware: [auth, profilCompleted],
      },
    },
    {
      path: '/m-engager/:missionId',
      name: 'singleMission',
      component: SingleMission,
      meta: {
        middleware: [auth, profilCompleted],
      },
    },
    {
      path: '/mes-badges',
      name: 'mySoftSkills',
      component: MySoftSkills,
      meta: {
        middleware: [auth, profilCompleted],
      },
    },
    {
      path: '/mes-experiences/:experienceId?',
      name: 'myJourney',
      component: MyJourney,
      meta: {
        middleware: [auth, profilCompleted],
      },
    },
    {
      path: '/profil/:activePage?/:experienceId?',
      name: 'mySoftSkillsAndMyJourney',
      component: mySoftSkillsAndMyJourney,
      meta: {
        middleware: [auth, profilCompleted],
      },
    },
    {
      path: '/badge/:skill_name/:skill_level/:id',
      name: 'badge',
      component: BadgeDisplayer,
    },
    {
      path: '/badge/:id',
      name: 'badgeId',
      component: BadgeDisplayer,
    },
    {
      path: '/iframe/chatbot',
      name: 'iframeChatbot',
      component: iframeChatbot,
    },
    {
      path: '/experiences/demarrer-evaluation-externe/:token',
      name: 'startExternalEvaluation',
      component: ExternalEvaluationStart,
    },
    {
      path: '/experiences/evaluation-externe/:token/:evaluationStep?',
      name: 'experience_evaluation',
      component: ExternalEvaluationWithUrl,
    },
    {
      path: '/telecharger/:option',
      name: 'experience_select_modal',
      component: ExperiencesSelectModal,
      meta: {
        middleware: [auth, profilCompleted],
      },
    },
    {
      path: '/experiences/demarrer-mon-auto-evaluation/:token',
      name: 'startAutoEvaluation',
      component: StartAutoEvaluation,
    },
    {
      path: '/experiences/:experienceId/add-contact',
      name: 'experience_add_contact',
      component: ExperienceContact,
      meta: {
        middleware: [auth, profilCompleted],
      },
    },
    {
      path: '/experiences/:experienceId/valoriser-par-mail',
      name: 'valueByEmail',
      component: ValueByMail,
      meta: {
        middleware: [auth, profilCompleted],
      },
    },
    {
      path: '/experiences/:experienceId/valoriser',
      name: 'experience_valoriser',
      component: ExperienceContact,
      meta: {
        middleware: [auth, profilCompleted],
      },
    },
    {
      path: '/experiences/auto-evaluation/:token',
      name: 'experience_auto_evaluation',
      component: ExperienceAutoEvaluation,
    },
    {
      path: '/iframe/experiences/evaluation/:token',
      name: 'iframe_experience_evaluation_referent',
      component: IframeExperienceEvaluationReferent,
    },
    {
      path: '/iframe/experiences',
      name: 'iframeExperiences',
      component: IframeExperiences,
      children: [{
        path: ':id',
        name: 'iframeUniqueExperience',
        component: IframeExperiences,
        children: [{
          path: 'evaluation',
          name: 'iframeUniqueExperienceEvaluation',
          component: IframeExperiences,
        },
        {
          path: 'competences',
          name: 'iframeUniqueExperienceCompetences',
          component: IframeExperiences,
        },
        {
          path: 'description',
          name: 'iframeUniqueExperienceDescription',
          component: IframeExperiences,
        },
        ],
      }],
    },
    {
      path: '/jobs',
      name: 'jobs',
      component: Jobs,
      meta: {
        middleware: [auth, profilCompleted],
      },
    },
    {
      path: '/jobs/:id',
      name: 'jobsSingle',
      component: JobsSingle,
      meta: {
        middleware: [auth, profilCompleted],
      },
    },
    {
      path: '/ressources-insertion-pro/:id',
      name: 'proResourceSingle',
      component: ProResourceSingle,
      meta: {
        middleware: [auth, profilCompleted],
      },
    },
    {
      path: '/mentions-legales',
      name: 'mentions_legales',
      component: MentionsLegales,
    },
    {
      path: '/a-propos',
      name: 'apropos',
      component: APropos,
    },
    {
      path: '/comment-ca-marche',
      name: 'comment_ca_marche',
      component: CommentCaMarche,
    },
    {
      path: '/soft-skills',
      name: 'soft_skills',
      component: SoftSkills,
    },
    {
      path: '/soft-skills/:familyName',
      name: 'family_name',
      component: SoftSkillsFamily,
    },
    {
      path: '/devenir-partenaire',
      name: 'devenir_partenaire',
      component: DevenirPartenaire,
    },
    {
      path: '/notre-organisation',
      name: 'notre_organisation',
      component: NotreOrganisation,
    },
    {
      path: '/manifeste-soft-skills-egalite-des-chances',
      name: 'manifeste',
      component: Manifeste,
    },
    {
      path: '/nous-contacter',
      name: 'contact',
      component: Contact,
    },
    {
      path: '/404',
      name: '404',
      component: PageNotFound,
    },
    {
      path: '/401',
      name: '401',
      component: NotAuthorized,
    },
    {
      path: '/500',
      name: '500',
      component: ServerInternalError,
    },
    {
      path: '/503',
      name: '503',
      component: ServerError,
    },
    {
      path: '/revele-la-force-qui-est-en-toi',
      name: 'revele_la_force_qui_est_en_toi',
      component: ReveleLaForceQuiEstEnToi,
    },
    {
      path: '/macif',
      name: 'macif',
      component: Macif,
    },
    {
      path: '/mission-to-skill',
      name: 'mission_to_skill',
      component: MissionToSkills,
    },
    {
      path: '/phygital-game/:dailyCode',
      name: 'phygital',
      component: PhyGital,
    },
    {
      path: '/a1-connect/sso',
      name: 'a1-connect',
      beforeEnter() {
        window.location.replace('/a1-connect/auth');
      },
    },
    {
      // it must remain last
      path: '/*',
      name: 'notFound',
      component: PageNotFound,
    },
  ],
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { top: 0 };
  },
});

const middlewarePipeline = (context, middleware, index) => {
  const nextMiddleware = middleware[index];
  if (!nextMiddleware) {
    return context.next;
  }
  return () => {
    nextMiddleware({
      ...context,
      next: middlewarePipeline(context, middleware, index + 1),
    });
  };
};

// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  const { middleware } = to.meta;
  const context = {
    to, from, next, router,
  };

  if (!middleware) {
    return next();
  }

  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
