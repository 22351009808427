<template>
	<nav class="navbar navbar-expand-lg fixed-top navbar-light">
		<div class="container">
			<div
				class="navbar-brand"
			>
				<div class="btn-holder d-flex justify-content-end align-items-center d-flex d-lg-none">
					<button class="navbar-toggler ml-0" type="button" @click="toggleNavMenu">
						<span />
					</button>
					<a class="nav-opener" href="#">
						<span />
					</a>
				</div>
				<router-link
					:to="{ name: 'mySteps', params: { activeTab: 'me-preparer' } }"
					exact
				>
					<img
						src="/images/logo-header.svg"
						data-src="/images/logo-header.svg"
						alt="Jobready logo"
						class="logo-header lg d-none d-sm-block d-xs-none"
					>
					<img
						class="logo-mobile-header sm d-block d-sm-none"
						data-src="/images/logo-sm.svg"
						src="/images/logo-sm.svg"
						alt="Jobready logo"
					>
				</router-link>
			</div>
			<div class="nav-holder d-lg-flex justify-content-lg-between align-items-lg-center flex-grow-1">
				<div
					id="navbarMenu"
					class="collapse navbar-collapse navbar--auth"
				>
					<div class="btn-holder navbar-toggler-menu  d-flex justify-content-end align-items-center d-flex d-lg-none">
						<button class="navbar-toggler ml-0" type="button" @click="toggleNavMenu">
							<span />
						</button>
						<a class="nav-opener" href="#">
							<span />
						</a>
					</div>
					<div class="user-info d-lg-none mb-5">
						<router-link tag="div" :to="{ name: 'mySteps', params: { activeTab: 'me-preparer' } }" exact class="auth-user__img auth-user__img--medium">
							<img
								:src="user && user.picture ? `${$laravel.avatarsFolder}/${user.picture}` : '/images/Mascotte-243.svg'"
								:srcset="user && user.picture ? `${$laravel.avatarsFolder}/${user.picture} 2x` : '/images/Mascotte-243.svg 2x'"
								alt="photo de profil"
								loading="lazy"
								@click="toggleNavMenu"
							>
						</router-link>
						<div class="auth-user__name mt-3">
							<span>Bonjour 👋</span> <br>
							<span>{{ user.full_name }}</span>
						</div>
					</div>
					<ul
						id="nav"
						class="navbar-nav"
						@click.stop="removeNavMenu"
					>
						<li
							:class="{ active: $route.name === 'mySteps' }"
							class="nav-item"
						>
							<router-link
								:to="{ name: 'mySteps', params: { activeTab: 'me-preparer' } }"
								exact
								class="nav-link"
							>
								<i class="icon-arrow-r mr-1 d-lg-none" />
								Mes étapes
							</router-link>
						</li>
						<li class="nav-item" :class="{ active: $route.name === 'resources' }">
							<router-link :to="{ name: 'resources' }" exact class="nav-link">
								<i class="icon-arrow-r mr-1 d-lg-none" />
								Ressources
							</router-link>
						</li>
						<li
							class="nav-item"
							:class="{ active: $route.name === 'jobs' }"
						>
							<router-link
								:to="{ name: 'jobs' }"
								exact
								class="nav-link"
							>
								<i class="icon-arrow-r mr-1 d-lg-none" />
								Trouver une offre
							</router-link>
							<span class="chip">version béta</span>
						</li>
					</ul>
				</div>
				<div class="btn-holder d-flex justify-content-end align-items-center flex-shrink-0">
					<ul class="navbar-nav mr-2 d-none d-lg-inline">
						<li class="nav-item">
							<a href="#" class="nav-link" @click="toggleMenu">
								Mon profil
							</a>
						</li>
					</ul>
					<ul class="navbar-nav mr-2 pb-0 d-lg-none">
						<li class="nav-item mb-0">
							<span class="nav-link" @click="toggleMenu">
								Mon profil
							</span>
						</li>
					</ul>
					<div
						class="auth-user__img d-none d-lg-inline-block"
					>
						<img
							:src="user && user.picture ? `${$laravel.avatarsFolder}/${user.picture}` : '/images/Mascotte-243.svg'"
							:srcset="user && user.picture ? `${$laravel.avatarsFolder}/${user.picture} 2x` : '/images/Mascotte-243.svg 2x'"
							alt="photo de profil"
							loading="lazy"
							@mouseover="toggleMenu"
							@focusin="toggleMenu"
						>
					</div>
					<div class="auth-user__img d-lg-none">
						<img
							:src="user && user.picture ? `${$laravel.avatarsFolder}/${user.picture}` : '/images/Mascotte-243.svg'"
							:srcset="user && user.picture ? `${$laravel.avatarsFolder}/${user.picture} 2x` : '/images/Mascotte-243.svg 2x'"
							alt="photo de profil"
							loading="lazy"
							@click="toggleMenu"
						>
					</div>
				</div>
			</div>
			<auth-menu
				:is-show="showMenu"
				@hideMenu="() => showMenu = false"
				@exit="() => showMenu = false"
			/>
			<vision />
			<georges v-if="!noGeorge" />
		</div>
	</nav>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import authMenu from '_components/layout/authMenu.vue';
import Georges from '_components/layout/georges.vue';
import Vision from '_components/visiontrust/Vision.vue';

export default {
  name: 'AuthHeader',
  components: { authMenu, Georges, Vision },

  props: {
    noGeorge: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      loginModalOpen: false,
      registerModalOpen: false,
      showMenu: false,
    };
  },

  computed: mapState('userStore', ['user']),

  created() {
    this.fetchAuthUser();
  },

  methods: {
    ...mapActions('userStore', ['fetchAuthUser']),
    toggleMenu() {
      this.showMenu = !this.showMenu;
      this.removeNavMenu();
    },

    /**
		 * Close the menu
		 */
    removeNavMenu: () => {
      if (document.body.classList.contains('nav-active')) {
        document.body.classList.remove('nav-active');
      }
    },

    /**
		 * Open the menu
		 */
    toggleNavMenu: () => {
      document.body.classList.toggle('nav-active');
    },
  },
};

</script>

<style lang="scss" scoped>
@import "_sass/_global.scss";
@import "_sass/vendors/_include-media.scss";

.navbar-brand {
	display: flex;
	align-items: center;
	width: 140px;
	gap: 10px;
}

.auth-user__img {
	display: block;
	border: 4px solid $yellow;
	border-radius: 50%;
	min-height: 40px;
	min-width: 40px;
	width: 40px;
	height: 40px;
	cursor: pointer;

	img {
		width: 100%;
    aspect-ratio: 1;
		border-radius: 50%;
	}
	&.auth-user__img--medium {
		min-height: 70px;
		min-width: 70px;
		width: 70px;
		height: 70px;
	}
}
.navbar {
	height: 60px;
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
	background: white;
}

.navbar-nav {
  font-size: 14px;
}

.navbar-collapse {
	z-index: 3;
}
.logo-header {
	height: 40px;
}
.logo-mobile-header {
  height: 30px;
}

@include media('<=tablet') {
	.navbar-toggler-menu {
		position: absolute;
		right: 20px;
	}
}

.chip {
	padding: 4px 8px;
	border-radius: 16px;
	background-color: #F2F2E6;
	font-size: 12px;
}
</style>
