<template>
	<div class="chatbot-container">
		<div
			ref="chatbotBody"
			class="chatbot-content scrollbar"
		>
			<div class="inner">
				<message
					v-for="(message, msgIndex) in messages"
					:key="msgIndex"
					:message="message"
					:end-of-chatbot="endOfChatbot"
					:is-last="msgIndex + 1 == messages.length"
					:is-second-last="
						showMessage
							? msgIndex + 3 == messages.length
							: msgIndex + 2 == messages.length
					"
					:call-machine-name="callMachineName"
					:help-question="question.help"
					@previousQuestion="undoQuestion(message.key)"
					@showHelpMessage="showHelpMessage"
				/>
				<div
					v-if="isTyping"
					class="message-block"
				>
					<span class="cta-btn">
						<span class="sr-only">
							Georges le Toucan
						</span>
						<img
							src="/images/mascotte-240.svg"
							alt="Georges"
						>
					</span>
					<div class="message-feed">
						<!-- eslint-disable vue/singleline-html-element-content-newline -->
						<div class="typing-indicator">
							<span />
							<span />
							<span />
						</div>
						<!-- eslint-enable vue/singleline-html-element-content-newline -->
					</div>
				</div>

				<div
					v-if="chatbotLoader"
					class="spinner-wrapper mt-5"
				>
					<span class="counterWord">
						{{ countDownWord }}
					</span>
					<!-- Placed counter outside of animated div -->
					<span class="counter">
						{{ countDown }}
					</span>
					<div
						class="spinner-border chatbot-spinner"
						role="status"
					>
						<span class="sr-only">
							Loading...
						</span>
					</div>
				</div>
			</div>
			<select-date
				v-if="type === 'selectDate'"
				:options="widgetProps"
				:question-key="question.key"
				@answered="response"
				@pushMessage="pushMessage"
				@disableSelectDate="disableInputs"
			/>
			<select-year
				v-if="type === 'selectYear'"
				:options="widgetProps"
				:question-key="question.key"
				@answered="response"
				@pushMessage="pushMessage"
				@disableSelectDate="disableInputs"
			/>
			<tag-list
				v-else-if="type === 'tagList'"
				:options="widgetProps"
				:question-key="question.key"
				@answered="response"
				@pushMessage="pushMessage"
				@pushOtherQuestion="pushOtherQuestion"
				@disableTagList="disableInputs"
			/>
			<single-choice
				v-else-if="type === 'singleChoice'"
				:options="widgetProps"
				:question-key="question.key"
				@answered="response"
				@pushMessage="pushMessage"
				@pushOtherQuestion="pushOtherQuestion"
				@disableSingleChoice="disableInputs"
			/>
			<multi-check
				v-else-if="type === 'multiCheck'"
				:options="widgetProps"
				:question-key="question.key"
				@answered="response"
				@pushMessage="pushMessage"
				@pushMultiCheckOtherResponse="pushMultiCheckOtherResponse"
				@disableMultiCheck="disableInputs"
			/>
			<multi-check-button
				v-else-if="type === 'multiCheckButton'"
				:options="widgetProps"
				:question-key="question.key"
				@answered="response"
				@pushMessage="pushMessage"
				@pushMultiCheckOtherResponse="pushMultiCheckOtherResponse"
				@disableMultiCheck="disableInputs"
			/>
			<skills-check-list
				v-else-if="type === 'skillsCheckList'"
				:options="widgetProps"
				:question-key="question.key"
				@answered="response"
				@pushMessage="pushMessage"
				@disableSkillsCheckList="disableInputs"
			/>
			<token-input
				v-else-if="type === 'tokenInput'"
				v-model="inputText"
				:widget-props="widgetProps"
				:question-key="question.key"
				@input="scrollToEnd"
				@empty-value="submitEmptyForm"
				@click="submitForm"
			/>
			<search-input
				v-else-if="type == 'freeInput' && widgetProps.seacheable"
				v-model="inputText"
				:widget-props="widgetProps"
				:placeholder="widgetProps.placeholder"
				@input="scrollToEnd"
				@empty-value="submitEmptyForm"
				@click="submitForm"
			/>
			<div
				v-if="type == 'freeInput' && !widgetProps.seacheable"
				class="chat-form"
				@click="scrollToEnd"
			>
				<form>
					<div class="input-holder">
						<label
							for="text"
							class="sr-only"
						>
							Écris ta réponse
						</label>
						<template v-if="question.key === 'direction'">
							<address-search
								v-if="apiAddressInput"
								id="map"
								classname="form-control chatbot-textarea"
								:placeholder="widgetProps.placeholder"
								@clear="inputText = ''"
								@setAddress="getAddressData"
							/>
							<input
								v-else
								list="schools"
								class="form-control chatbot-textarea"
								@input="(e) => handleEnterAddress(e.target.value)"
								@keypress.13.native="submitForm"
							>
						</template>
						<!-- FIXME id should not be text as it is displayed multiple time -->
						<div
							v-else-if="
								question.key === 'secondarySchool'
									|| question.key === 'highSchool'
									|| question.key === 'school'
							"
						>
							<input
								v-model="inputText"
								list="schools"
								class="form-control chatbot-textarea"
								:placeholder="widgetProps.placeholder"
								@keyup="searchSchools($event.target.value)"
								@keypress.13.native="submitForm"
							>
							<datalist id="schools">
								<option
									v-for="(school, key) in schools"
									:key="key"
									:value="school"
								/>
							</datalist>
						</div>
						<template v-else>
							<input
								id="chatbot-text"
								ref="chatbotTextarea"
								v-model="inputText"
								class="form-control chatbot-textarea"
								:placeholder="widgetProps.placeholder"
								:disabled="type !== 'freeInput'"
								@keypress.13.native="submitForm"
							>
						</template>
					</div>
				</form>
			</div>
			<div
				v-if="type == 'freeInput' && !widgetProps.seacheable"
				class="bottom-block-wrapper d-flex justify-content-center align-items-center mb-3"
			>
				<div
					v-if="type === 'freeInput' && widgetProps.none"
					class="bottom-block d-flex justify-content-center mb-2"
				>
					<button
						class="btn btn-secondary-chatbot mr-2 btn-chatbot"
						type="submit"
						style="float: left"
						@click="submitEmptyForm"
					>
						{{ widgetProps.none }}
					</button>
				</div>
				<div
					v-if="
						type === 'freeInput'
							&& question.key === 'direction'
							&& apiAddressInput
					"
					class="bottom-block d-flex justify-content-center row mx-0"
				>
					<button
						class="btn btn-secondary-chatbot mr-2 mb-2 btn-chatbot"
						type="submit"
						@click="noAddress($event, 'Je ne vis pas en France')"
					>
						Je ne vis pas en France
					</button>
					<button
						class="btn btn-secondary-chatbot mr-2 mb-2 btn-chatbot"
						type="submit"
						@click="apiAddressInput = false"
					>
						Je ne trouve pas mon adresse
					</button>
				</div>
				<div class="d-flex justify-content-center">
					<button
						v-if="type === 'freeInput'"
						type="submit"
						class="btn btn-primary btn-chatbot mb-2"
						:disabled="disabledInput"
						@click="submitForm"
					>
						Valider
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Message from '_components/chatbot/message.vue';
import SingleChoice from '_components/chatbot/singleChoice.vue';
import SelectDate from '_components/chatbot/selectDate.vue';
import SelectYear from '_components/chatbot/selectYear.vue';
import AddressSearch from '_components/chatbot/addressSearch.vue';
import { mapActions, mapGetters } from 'vuex';
import SearchInput from '_components/chatbot/searchInput.vue';
import MultiCheckButton from '_components/chatbot/multiCheckButton.vue';
import TokenInput from '_components/chatbot/tokenInput.vue';
import { VISIONS } from '_constants/tokens';
import chatbotQuery from '_graphql/chatbot/chatbot.gql';

export default {
  components: {
    message: Message,
    'single-choice': SingleChoice,
    'select-date': SelectDate,
    // 'vue-google-autocomplete': VueGoogleAutocomplete,
    'address-search': AddressSearch,
    'select-year': SelectYear,
    SearchInput,
    TokenInput,
    MultiCheckButton,
  },
  props: {
    machineName: {
      type: String,
      default: '',
    },
    chatbotId: {
      type: String,
      default: '',
    },
    sessionContext: {
      type: String,
      default: '',
    },
    sessionMessages: {
      type: String,
      default: '',
    },
    sessionMachineName: {
      type: String,
      default: '',
    },
    cleanSessionBool: {
      type: Boolean,
      required: true,
    },
    closable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return this.getInitialData();
  },

  computed: {
    disabledInput() {
      return (
        this.inputText === '' || this.type !== 'freeInput' || !this.inputText
      );
    },
    userTokens() {
      return this.getUserTokens().map((userToken) => userToken.token);
    },
    messagesCount() {
      return this.messages.length;
    },
    redirectUrl() {
      return this.$route.query.redirect;
    },
  },

  mounted() {
    window.addEventListener('resize', this.scrollToEnd);
  },

  created() {
    this.initializeChatbot();
  },
  // Scroll down when new message or new widget pushed
  updated() {
    this.scrollToEnd();
  },

  methods: {
    ...mapActions('jobreadynessStore', ['setJobreadynessAction']),
    ...mapGetters('userStore', ['getUserTokens']),
    getInitialData() {
      return {
        type: '',
        widgetProps: {},
        messages: [],
        context: {},
        question: {},
        options: [],
        inputText: '',
        other: false,
        // store response of multiCheckList if other checked
        tmpResponse: [],
        placeholder: '',
        callMachineName: '',
        endOfChatbot: true,
        otherMessage: {
          label: 'Ok, du coup précise-moi cet autre !',
        },
        schools: [],
        isTyping: false,
        chatbotLoader: false,
        countDown: 3,
        countDownWords: ['Enregistrement', 'Analyse', 'Calcul'],
        countDownWord: '',
        isMobile: false,
        apiAddressInput: true,
        showMessage: false,
        redirectionNextQuestion: '',
      };
    },

    initializeChatbot() {
      // Get The welcome message for the chatbot
      this.callMachineName =					this.$route.name === 'profilChatbot' ? 'profil' : this.machineName;
      this.$apollo
        .query({
          query: chatbotQuery,
          variables: {
            machineName: this.callMachineName,
          },
        })
        .then((response) => {
          this.messages.push({
            label: response.data.scenarioByMachineName.start_msg,
          });
          if (response.data.scenarioByMachineName.start_msg_optional && this.redirectUrl !== '/' && this.redirectUrl !== '/connexion') {
            this.messages.push({
              label: response.data.scenarioByMachineName.start_msg_optional,
            });
          }
          this.response();
          this.$emit('showGeorges');
        });
    },

    countDownTimer() {
      if (this.countDown >= 1) {
        this.countDownWord = this.countDownWords[4 - (this.countDown + 1)];
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    // Method called by child components when the user submit his answer
    response(data) {
      this.showMessage = false;
      // contain the answers of the user for a scenario
      const payload = {
        machineName: this.callMachineName,
        context: this.context,
        sessionContextLabel: this.sessionContext,
        sessionMessagesLabel: this.sessionMessages,
        sessionMachineNameLabel: this.sessionMachineName,
        tenantId: window.iframe.tenantId,
      };

      // contain the payload, the response of the user and the context
      if (data) {
        payload.response = data;
        this.context[data.key] = data.value;
        payload.messages = this.messages;
      }
      this.inputText = '';
      this.schools = [];
      this.type = '';
      // Post method to submit the response and get the next question
      axios
        .post('/api/chatbot', payload, { params: { redirect: this.redirectUrl } })
        .then((response) => {
          if (this.context.redirectNextQuestion) {
            delete this.context.redirectNextQuestion;
          }
          const talkMsg = response.data.nextQuestion.talk_msg;
          this.$emit('progress', response.data.progress);
          if (talkMsg) {
            this.isTyping = true;
            setTimeout(() => {
              this.isTyping = false;
              this.process(response);
            }, 1000);
          } else {
            this.process(response);
          }
          this.$emit('messages-count', this.messagesCount);
        })
        .catch(() => {
          const message = {
            label: 'Une erreur est survenue, rafraîchis la page !',
          };
          this.isTyping = false;
          this.messages.push(message);
        });
    },
    process(response) {
      let timeOut = 0;
      if (response.data.nextQuestion.key === 'skills') {
        this.chatbotLoader = true;
        this.countDown = 3;
        this.countDownTimer();
        timeOut = 3000;
      } else {
        this.isTyping = true;
        timeOut = 1000;
      }
      setTimeout(() => {
        const { redirect } = response.data;
        if (redirect) {
          this.isTyping = false;
          if (redirect.type === 'prevUrl') {
            this.$router.push(redirect.path);
            return;
          }
          if (redirect.type === 'url') {
            let link = `/${redirect.path}`;

            if (redirect.firstParam) {
              link += `/${redirect.firstParam}`;
              if (redirect.secondParam) {
                link += `/${redirect.secondParam}`;
              }
            } else if (redirect.questionKey) {
              link += `/${redirect.questionKey}`;
            }
            // check if its an iframe
            if (window.iframe.token) {
              this.chatbotLoader = true;
              this.countDown = 3;
              this.countDownTimer();
              this.messages.push({
                label: 'Ton expérience a été enregistrée avec succés !!',
              });
              setTimeout(() => {
                Object.assign(this.$data, this.getInitialData());
                this.initializeChatbot();
              }, 3000);
              return;
            }
            if (!this.userTokens.includes(VISIONS)) {
              this.setJobreadynessAction(this.messages);
            }
            this.$router.push(link);
          } else if (redirect.type === 'machineName') {
            if (redirect.path) {
              if (redirect.path !== 'skills' && window.iframe.tenantId === null) {
                this.$router.push({
                  name: 'chatbotByMachineName',
                  params: { machineName: redirect.path },
                });
              }
              this.callMachineName = redirect.path;
              if (redirect.questionKey) {
                this.context.redirectNextQuestion = redirect.questionKey;
              }
              this.question = response.data.nextQuestion;
              this.messages.push(this.question);
              this.response();
            }
          } else {
            console.log('Neither an url nor a machine name is provided');
          }
          return;
        }
        this.question = response.data.nextQuestion;
        this.inputText = '';
        this.schools = [];
        this.type = '';
        if (this.question.widget) {
          this.type = this.question.widget.type;
          this.widgetProps = this.question.widget.widgetProps;
          this.placeholder =							this.type === 'freeInput'
							  ? 'Écris ta réponse'
							  : 'Choisis ta réponse';
        }
        this.messages.push(this.question);
        this.endOfChatbot =						this.type !== '' && this.callMachineName !== 'skills';
        // Need to use nextTick because the element is not yet set
        this.$nextTick(() => {
          // Focus in the texterea
          if (this.$refs.chatbotTextarea && !this.isMobile) {
            this.$refs.chatbotTextarea.$el.focus();
          }
        });
        this.isTyping = false;
        this.chatbotLoader = false;
        this.scrollToLastQuestion();
      }, timeOut);
    },

    scrollToLastQuestion() {
      this.$nextTick(() => {
        const element = document.getElementById('is-last');
        if (
          element
						&& this.$refs.chatbotBody
						&& !this.checkInView(element, false)
        ) {
          element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
      });
    },

    // Check if the last message element is visible in the viewport
    checkInView(elem, partial) {
      const { chatbotBody } = this.$refs;
      const elemTop = elem.offsetTop - chatbotBody.offsetTop;
      const elemBottom = elemTop + chatbotBody.clientHeight;
      const isTotal = elemTop >= 0 && elemBottom <= chatbotBody.clientHeight;
      const isPart =					((elemTop < 0 && elemBottom > 0)
							|| (elemTop > 0 && elemTop <= chatbotBody.clientHeight))
					&& partial;
      return isTotal || isPart;
    },

    // submit form for free input questions
    submitForm(e) {
      e.preventDefault();
      if (this.inputText === '') {
        return;
      }
      if (typeof this.inputText === 'string') {
        this.inputText = this.inputText.trim();
      }
      // reset data to contain the data of the axios request of the response function
      let data = {};
      // messageContent will contain the user message that will be shown in the chatbot
      let messageContent = '';
      // the message object that will be pushed in the messages array
      let message = {};
      // If other selected in MultiCheckList
      if (this.tmpResponse.length !== 0) {
        // push the Other response in that array of responses
        this.tmpResponse.value.push(this.inputText);
        // remove -1 value from the array of responses
        this.tmpResponse.value.splice(this.tmpResponse.value.indexOf('-1'), 1);
        // data that will be sent with axios request
        data = {
          key: this.question.key,
          value: this.tmpResponse.value,
          other: this.other,
        };

        // Create the message to display in the chatbot
        this.tmpResponse.value.forEach((id) => {
          if (
            !Object.values(this.widgetProps.values).find(
              (value) => value === id,
            )
          ) {
            messageContent += `${id}\n`;
          }
        });
        // message that will be pushed in messages array
        message = {
          label: messageContent,
          user: true,
          key: this.question.key,
        };
      } else {
        let msg = null;
        // if the other response is from tagList OR singleChoiceList
        if (typeof this.inputText === 'string') {
          msg = this.inputText;
        } else {
          msg =							`${this.inputText.street_number
							 } ${
							 this.inputText.route
							 } ${
							 this.inputText.postal_code
							 } ${
							 this.inputText.region
							 } ${
							 this.inputText.country}`;
        }
        data = {
          key: this.question.key,
          value: this.inputText,
          other: this.other,
        };
        message = {
          label: msg,
          user: true,
          key: this.question.key,
        };
      }
      this.other = false;
      this.messages.push(message);
      this.inputText = '';
      this.tmpResponse = [];
      this.response(data);
    },
    // submit form at click on None button
    submitEmptyForm(e) {
      e.preventDefault();
      const data = {
        key: this.question.key,
        value: '',
      };

      this.response(data);
      const message = {
        label: this.widgetProps.none,
        user: true,
        key: this.question.key,
      };

      this.messages.push(message);
      this.inputText = '';
    },
    // push new message in messages array
    pushMessage(message) {
      this.messages.push(message);
    },
    // add the help message to messages array when it exists
    showHelpMessage() {
      this.showMessage = true;
      if (this.question.help) {
        const message = {
          label: this.question.help,
        };
        this.question.help = null;
        this.messages.push(message);
      }
    },
    // hide widget after using it
    disableInputs() {
      this.type = '';
    },
    // scroll to the bottom of the chatbot when a new message or a new widget are added
    scrollToEnd() {
      if (this.$refs.chatbotBody) {
        this.$refs.chatbotBody.scrollTop = this.$refs.chatbotBody.scrollHeight;
      }
    },
    pushOtherQuestion() {
      this.widgetProps.none = false;
      this.type = 'freeInput';
      this.placeholder = 'Écris ta réponse';
      this.messages.push(this.otherMessage);
      this.other = true;
    },
    // push other response label in the chatbot
    pushMultiCheckOtherResponse(data) {
      // remove widget from the chatbot until the user gives a response
      this.type = 'freeInput';
      this.widgetProps.none = false;
      this.placeholder = 'Écris ta réponse';
      this.other = true;
      this.tmpResponse = data;
      this.messages.push(this.otherMessage);
    },
    // post http request to clean session instances
    async cleanSession() {
      const payload = {
        sessionContextLabel: this.sessionContext,
        sessionMessagesLabel: this.sessionMessages,
        sessionMachineNameLabel: this.sessionMachineName,
      };
      axios
        .post('/api/chatbot/clean', payload)
        .then(() => {
          // Initialise chatbot variables
          this.type = '';
          this.widgetProps = {};
          this.messages = [];
          this.context = {};
          this.question = {};
          this.options = [];
          this.inputText = '';
          this.other = false;
          this.tmpResponse = [];
          // Get The welcome message for the chatbot
          this.$apollo
            .query({
              query: chatbotQuery,
              variables: {
                machineName: this.callMachineName,
              },
            })
            .then((response) => {
              this.messages.push({
                label: response.data.scenarioByMachineName.start_msg,
              });
              if (response.data.scenarioByMachineName.start_msg_optional) {
                this.messages.push({
                  label: response.data.scenarioByMachineName.start_msg_optional,
                });
              }
              this.response();
            });
        })
        .catch(() => {
          const message = {
            label: 'Une erreur est survenue, rafraîchis la page !',
          };
          this.messages.push(message);
        });
    },
    undoQuestion(questionKey) {
      // contain the answers of the user for a scenario
      const payload = {
        machineName: this.callMachineName,
        sessionContextLabel: this.sessionContext,
        sessionMessagesLabel: this.sessionMessages,
        sessionMachineNameLabel: this.sessionMachineName,
        questionKey,
      };
      // Post method to return back to the previous question
      axios
        .post('/api/chatbot/previous', payload)
        .then((response) => {
          if (response.data.error) {
            const message = {
              label: response.data.error,
            };
            this.messages.push(message);
          } else {
            this.messages = response.data.messages;
            this.question = response.data.previousQuestion;
            this.$emit('progress', response.data.progress);
            this.inputText = '';
            this.type = '';
            if (this.question.widget) {
              this.type = this.question.widget.type;
              this.widgetProps = this.question.widget.widgetProps;
              this.placeholder =										this.type === 'freeInput'
										  ? 'Écris ta réponse'
										  : 'Choisis ta réponse';
            }
            this.messages.push(this.question);
            this.apiAddressInput = true;
          }
          this.scrollToLastQuestion();
        })
        .catch(() => {
          const message = {
            label: 'Une erreur est survenue, rafraîchis la page !',
          };
          this.messages.push(message);
        });
    },

    getAddressData(addressData) {
      this.inputText = {
        street_number: addressData.label,
        route: '',
        postal_code: addressData.postcode,
        region: addressData.city,
        country: 'France',
      };
    },

    noAddress(e, address = null) {
      if (!address) {
        address = e.target.value;
      }
      this.updateAdressValue(address);
      this.apiAddressInput = true;
      this.submitForm(e);
    },

    handleEnterAddress(address) {
      if (address) {
        this.updateAdressValue(address);
        return;
      }
      this.inputText = null;
    },

    updateAdressValue(address) {
      this.inputText = {
        street_number: address,
        route: '',
        postal_code: '',
        region: '',
        country: '',
      };
    },

    searchSchools(searchContent) {
      const data = {
        searchContent,
        type: this.question.key,
      };
      axios
        .post('/api/getSchools', data)
        .then((response) => {
          if (response.data.schools.length > 0) {
            this.schools = response.data.schools;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    autofocus() {
      if (this.type === 'freeInput') {
        window.scrollTo(
          0,
          document.body.scrollHeight || document.documentElement.scrollHeight,
        );
        return true;
      }
      return false;
    },

    logout(e) {
      e.preventDefault();
      localStorage.auth = false;
      axios
        .post('/api/deconnexion')
        .then(() => {
          window.location.href = '/';
        })
        .catch((error) => {
          // TODO handle errors correctly
          console.log(error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "_sass/_global.scss";
@import "_sass/vendors/_include-media.scss";

.spinner-wrapper {
	text-align: center;
	position: relative;

	.counter {
    font-family: $font-family-bebas;
		position: absolute;
		top: 3px;
		right: 0;
		left: 0;
	}

  .counterWord {
    font-family: $font-family-sans-serif;
    position: absolute;
    top: -40px;
    right: 0;
    left: 0;
  }

  .spinner-border {
    color: #FFDF4A;
  }
}

.chatbot-container {
	width: 100%;
	@include media(">tablet") {
		background: $light;
		margin: 0 auto;
		max-width: 50rem;
		border-radius: 2rem;
		padding: 2rem 1rem 2rem 2rem;
	}
}

.chatbot-content {
	background: $light;
	overflow: auto;
	height: calc((var(--vh, 1vh) * 100) - 57px);
	max-height: calc((var(--vh, 1vh) * 100) - 57px);
	width: 100%;
	margin-top: 55px;
	padding: 2rem;
	@include media(">tablet") {
		max-height: 70vh;
		border-radius: 2rem;
		padding: 0 1rem 0 0;
		height: 60vh;
		margin-top: 0;
	}
}

@media (min-width: 768px) {
	.scrollbar::-webkit-scrollbar-track {
		border-radius: 60px;
		background: transparent;
	}

	.scrollbar::-webkit-scrollbar {
		width: 6px;
		margin-right: 10px;
		background-color: #f5f5f5;
	}

	.scrollbar::-webkit-scrollbar-thumb {
		background-color: rgba($primary, 0.5);
		border: none;
		border-radius: 60px;
	}
}
</style>
